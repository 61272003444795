import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Scroll from '../components/Scroll';

const IndexPage = () => (
  <Layout>
    <Sidebar />

    <div id="wrapper">
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <h1>OSSLAB</h1>
          <p>
            Deep Learning on Edge Device
          </p>
          <ul className="actions">
            <li>
              <Scroll type="id" element="two">
                <a href="#two" className="button">
                  Learn more
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>


      <section id="two" className="wrapper style3 fade-up">
        <div className="inner">
          <h2>What we do</h2>
          <p>
            Developing deep learning applications on edge devices
          </p>
          <div className="features">
            <section>
              <span className="icon major fa-android" />
              <h3>Fashion Lens</h3>
              <p>
                Fashion item detector on Android device.
              </p>
            </section>
            <section>
              <span className="icon major fa-microchip" />
              <h3>Edge Detector</h3>
              <p>
                Fashion item detector on Cloud Run
              </p>
            </section>

            <section>
              <span className="icon major fa-google" />
              <h3>Brand Name Bot</h3>
              <p>
                Brand name generator on Cloud Run
              </p>
            </section>
            <section>
              <span className="icon major fa-newspaper-o" />
              <h3>Newsmine</h3>
              <p>
                Chatbot using rule based system based on data mining and search engine.
              </p>
            </section>
          </div>

        </div>
      </section>

      <section id="three" className="wrapper style1 fade-up">
        <div className="inner">
          <h2>Get in touch</h2>
          <p>
            Improve deep learing model efficiency with us
          </p>
          <div className="style1">
            <section>
              <ul className="contact">
                <li>
                  <h3>Address</h3>
                  <span>
                    Jongno 51, Seoul, 03161
                    <br />
                    Republic of Korea
                  </span>
                </li>
                <li>
                  <h3>Email</h3>
                  <a href="/#">manager@osslab.com</a>
                </li>
                <li>
                  <br />
                  <br />
                </li>
                <li>
                  <br />
                  <br />           
                  <br />       
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default IndexPage;
